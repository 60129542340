


export const StationLinkCalculator = function (stationId, qualiferType) {
	let suffix = "";
	if(qualiferType === "Downstream Stage") {
		suffix = '/downstream';
	}
	return "/stations/" + stationId + suffix;
};


export const OperationalCatchmentsLinkCalculator = function (operationalCatchtmentName) {
	return `/data-explorer/operational-catchments/${operationalCatchtmentName}`;
}