import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ArrowLeftRight } from 'react-bootstrap-icons';
import { ArrowUpRightCircleFill } from 'react-bootstrap-icons';

import { useLocation } from 'react-router-dom';
import { useEffect } from "react";


import PlannedRoutesSpokesRenderer from './PlannedRoutesSpokesRenderer.js';

import statusInfo from '../lib/statusInfo.js';
const statusText = statusInfo.statusText;

function PlannedRoutes({ basePath, routes, forecastHeight, statusMappings, baseStations, mapFloodAreas, displayStation, plannedRouteType, mapName }) {
  	const location = useLocation();

	useEffect(() => {
		if(typeof window !== 'undefined') {
			window.scrollTo(0, 0);
		}
	}, [location]);


	let planIcon = <ArrowLeftRight />;
	if(plannedRouteType === "ESCAPE") {
		planIcon = <ArrowUpRightCircleFill />;
	}


	let displayEntries = [];
	if(routes) {
		let orderedKeys = Object.keys(routes).sort(function(aKey, bKey) {
            let a = routes[aKey];
            let b = routes[bKey];
            if(a.name < b.name) {
                return -1;
            }
            if(a.name > b.name) {
                return 1;
            }
            return 0;
        });

        for(let routeKey of orderedKeys) {
        	let route = routes[routeKey];
			let styles = {
				"CLEAR": {
					backgroundColor: (statusMappings && statusMappings['CLEAR'] && statusMappings['CLEAR'].color) || null
				},
				"RECENTLY_FLOODED": {
					backgroundColor: (statusMappings && statusMappings['RECENTLY_FLOODED'] && statusMappings['RECENTLY_FLOODED'].color) || null
				},
				"IN_PARTIAL_FLOOD": {
					backgroundColor: (statusMappings && statusMappings['IN_PARTIAL_FLOOD'] && statusMappings['IN_PARTIAL_FLOOD'].color) || null
				},
				"IN_FLOOD": {
					backgroundColor: (statusMappings && statusMappings['IN_FLOOD'] && statusMappings['IN_FLOOD'].color) || null
				}
			};
			let classes = {
				"CLEAR": ["text-white"],
				"RECENTLY_FLOODED": ["text-white"],
				"IN_PARTIAL_FLOOD": ["text-white"],
				"IN_FLOOD": ["text-white"]
			};

			if(route.status) {
				let thisEntryCols = [];
				thisEntryCols.push(<Col key="header_0" md={12} className="bg-dark text-white"><b>{route.name} {planIcon}</b></Col>);
				thisEntryCols.push(<Col key="header_1" md={12} className={(classes[route.status] || []).join(", ")} style={styles[route.status]}>{route.notes && <span><i>{route.notes}</i></span>}</Col>);
				thisEntryCols.push(<Col key="header_2" md={12} className={(classes[route.status] || []).join(", ")} style={styles[route.status]}>
					<b>{statusText[route.status] || "Unknown"}</b>
				</Col>);
				thisEntryCols.push(<Col key="header_3" md={12} className={(classes[route.status] || []).join(", ")} style={styles[route.status]}></Col>);

				displayEntries.push(<Row key={routeKey} style={{paddingBottom: "4px"}}>{thisEntryCols}</Row>);
				if(route.spokes) {
					displayEntries.push(<PlannedRoutesSpokesRenderer
						key={routeKey + "_entries"}
						route={route}
						mapFloodAreas={mapFloodAreas}
						baseStations={baseStations}
						statusMappings={statusMappings}
						forecastHeight={forecastHeight}
						statusText={statusText}
						displayStation={displayStation}
						mapName={mapName}
					/>)
				}
			}

        }
	}

	return <div>{displayEntries}</div>;
}

export default PlannedRoutes;