import { ArrowRightCircleFill, ArrowLeftCircleFill, ArrowUpCircleFill, ArrowDownCircleFill } from 'react-bootstrap-icons';

import FloodAreas from './FloodAreas.js';
import statusInfo from '../lib/statusInfo.js';
const statusText = statusInfo.statusText;


function PlannedRoutesSpokesRenderer({ baseStations, route, mapFloodAreas, statusMappings, forecastHeight, displayStation, mapName}) {
	if(!route || !route.spokes || !mapFloodAreas) {
		console.error("Cannot render route spokes")
		return null;
	}

	let spokeDisplays = [];
	let spokeKeys = Object.keys(route.spokes);
	for(let spokeKey of spokeKeys) {
		let mfasToRender = {};
		let spoke = route.spokes[spokeKey];
		for(let area of spoke) {
			// if(props.mapFloodAreas && props.mapFloodAreas[area.areaId]) {
			// 	let mfa = props.mapFloodAreas[area.areaId];
			// 	let arrow;
			// 	if(spokeKey == "B") {
			// 		arrow = <ArrowLeft />;
			// 	} else {
			// 		arrow = <ArrowRight />;
			// 	}
			// 	thisEntryCols.push(<Col key={area.areaId+"_0"} md={3} className="bg-secondary text-white"><b>{mfa.name} ({arrow})</b></Col>);
			// 	thisEntryCols.push(<Col key={area.areaId+"_1"} md={5} className={(classes[mfa.status] || []).join(", ")} style={styles[mfa.status]}><i>{mfa.description}</i></Col>);
			// 	thisEntryCols.push(<Col key={area.areaId+"_2"} md={2} className={(classes[route.status] || []).join(", ")} style={styles[mfa.status]}>
			// 		<b>{statusText[mfa.status] || "Unknown"}</b>
			// 	</Col>);
			// 	thisEntryCols.push(<Col key={area.areaId+"_3"} md={2} className={(classes[mfa.status] || []).join(", ")} style={styles[mfa.status]}></Col>);
			// }
			if(mapFloodAreas && mapFloodAreas[area.areaId]) {
				mfasToRender[area.areaId] = mapFloodAreas[area.areaId];
			}
		}

		let arrow = <ArrowRightCircleFill />;
		switch(spokeKey) {
			default:
				break;
			case "B":
			case "WEST":
				arrow = <ArrowLeftCircleFill />;
				break;
			case "NORTH":
				arrow = <ArrowUpCircleFill />
				break;
			case "SOUTH":
				arrow = <ArrowDownCircleFill />
				break;
		}
		spokeDisplays.push(<FloodAreas
			key={spokeKey.areaId}
			mapFloodAreas={mfasToRender}
			baseStations={baseStations}
			statusMappings={statusMappings}
			forecastHeight={forecastHeight}
			statusText={statusText}
			secondaryMode={true}
			includeIconWithName={arrow}
			displayStation={displayStation}
			mapName={mapName}
		/>)
	}

	return spokeDisplays;	
}

export default PlannedRoutesSpokesRenderer;

